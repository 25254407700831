<template>
    <div :class="['layout-inline-menu', {'layout-inline-menu-active': activeInlineProfile}]" v-if="!isMobile() && (isStatic() || isSlim() || isSidebar())">
        <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
            <img :src="userPicture" alt="avatar" style="width: 44px; height: 44px;">
            <span class="layout-inline-menu-text">{{identity.user.name}}</span>
            <i class="layout-inline-menu-icon pi pi-angle-down"></i>
        </a>

        <transition name="layout-inline-menu-action-panel">
            <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
                <li class="layout-inline-menu-action-item">
                    <a @click.prevent="logout">
                        <i class="pi pi-power-off pi-fw"></i>
                        <span>Logout</span>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item">
                    <a>
                        <i class="pi pi-cog pi-fw"></i>
                        <span>Settings</span>
                    </a>
                </li>
                <li class="layout-inline-menu-action-item">
                    <a>
                        <i class="pi pi-user pi-fw"></i>
                        <span>Profile</span>
                    </a>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { identity, useMsal } from "./service/Msal";

export default {
    name: 'AppInlineMenu',
    emits: ['change-inlinemenu'],
    props: {
        menuMode: String,
        activeInlineProfile: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const msal = useMsal();

        return {
            identity,
            userPicture: identity.pictureUrl(),
            async logout() {
                 await msal.logoutRedirect({
                    account: identity.user,
                    postLogoutRedirectUri: '/'
                });
            },
            onChangeActiveInlineMenu(event) {
                context.emit('change-inlinemenu', event);
                event.preventDefault();
            },
            isHorizontal() {
                return props.menuMode === 'horizontal';
            },
            isSlim() {
                return props.menuMode === 'slim';
            },
            isOverlay() {
                return props.menuMode === 'overlay';
            },
            isStatic() {
                return props.menuMode === 'static';
            },
            isSidebar() {
                return props.menuMode === 'sidebar';
            },
            isDesktop() {
                return window.innerWidth > 991;
            },
            isMobile() {
                return window.innerWidth <= 991;
            }
        };
    }
}
</script>