import axios from "axios";
import { msalInstance, identity } from "./Msal";
import * as msal from '@azure/msal-browser';
import { inject } from "vue";

let baseUrl = process.env.VUE_APP_APIROOTURL;
if(baseUrl == 'http://localhost:7071' && window.document.location.protocol == 'https:') {
    baseUrl = process.env.VUE_APP_APIROOTURLDEVHTTPS;
}

function getApiUrl(endpoint) {
    return `${baseUrl}/api/${endpoint}`;
}
const apiRequest = {
    scopes: ["api://58084b79-f382-46bd-9a0a-1042cf386c24/.default"],
    forceRefresh: false,
};

async function getToken() {
    const account = msalInstance.getAccountByHomeId(identity.user.homeAccountId);

    let token = null;
    try {
        token = await msalInstance.acquireTokenSilent({...apiRequest, account});
    } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            token = await msalInstance.acquireTokenRedirect(apiRequest);
        } else {
            console.error(error);   
        }
    }
    return token;
}

export const api = {
    async get(endpoint) {
        const token = await getToken();
        if (null === token) {
            return null;
        }

        return await axios.get(getApiUrl(endpoint), {
            headers: {
                'Authorization': `Bearer ${token.accessToken}`
            }
        });
    },
    async post(endpoint, data) {
        const token = await getToken();
        if (null === token) {
            return null;
        }

        return await axios.post(
            getApiUrl(endpoint), 
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token.accessToken}`
                }
            }
        );
    },
    async patch(endpoint, data) {
        const token = await getToken();
        if (null === token) {
            return null;
        }

        return await axios.patch(
            getApiUrl(endpoint), 
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token.accessToken}`
                }
            }
        );
    },
    async delete(endpoint) {
        const token = await getToken();
        if (null === token) {
            return null;
        }

        return await axios.delete(
            getApiUrl(endpoint), 
            {
                headers: {
                    'Authorization': `Bearer ${token.accessToken}`
                }
            }
        );
    }
}

export default {
    install(app) {
        app.provide('api', api);
    }
}

export function useApi() {
    return inject('api');
}