<template>
    <div class="login-body">
    </div>
</template>

<script>
import { onBeforeMount } from "vue"
import { useRouter } from "vue-router";
import { identity, useMsal } from "../service/Msal";

export default {
    setup() {
        const msal = useMsal();
        const router = useRouter()

        onBeforeMount(async () => {
            try {
                const response = await msal.handleRedirectPromise();

                if (null === response) {
                    msal.loginRedirect({
                        scopes: ['User.Read']
                    });

                    return;
                }

                // A priori, tout est OK : 
                //      1. on définit l'identity
                //      2. on va sur la homepage
                identity.isAuthenticated = true;
                identity.user = response.account;
                router.replace({path: '/'});
            } catch (err) {
                console.error('Error auth %o', err);
                router.replace({path: '/login'});
            }
        });
    }
}
</script>