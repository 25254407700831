<template>
	<div class="layout-topbar">
        <div class="layout-topbar-left">
            <a href="#" class="topbar-menu-button" @click="onMenuButtonClick($event)">
                <i class="pi pi-bars"></i>
            </a>

            <a href="#" class="logo">
                <img :src="'/layout/images/logo-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'">
            </a>

            <a href="#">
                <img :src="'/layout/images/appname-'+ (colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name"/>
            </a>
        </div>

        <AppMenu 
            :model="items" 
            :menuMode="menuMode" 
            :colorScheme="colorScheme" 
            :menuActive="menuActive" 
            :activeInlineProfile="activeInlineProfile"
            @sidebar-mouse-over="onSidebarMouseOver" 
            @sidebar-mouse-leave="onSidebarMouseLeave" 
            @toggle-menu="onToggleMenu" 
            @change-inlinemenu="onChangeActiveInlineMenu" 
            @menu-click="onMenuClick" 
            @root-menuitem-click="onRootMenuItemClick" 
            @menuitem-click="onMenuItemClick" />

        <div class="layout-topbar-right">
            <ul class="layout-topbar-right-items">
                <li id="profile" class="profile-item" :class="{'active-topmenuitem': topbarMenuActive}">
                    <a href="#" @click="onTopbarItemClick($event, 'profile')">
                        <img :src="identityPictureUrl">
                    </a>

                    <ul class="fadeInDown">
                        <li role="menuitem">
                            <a href="#" @click.prevent="logout()">
                                <i class="pi pi-fw pi-sign-out"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import AppMenu from './AppMenu.vue';
import { identity } from './service/Msal';

export default {
	emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'menubutton-click', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'toggle-menu', 'change-inlinemenu', 'topbar-item-click'],
    inject: ['msal'],
    props: {
        items: Array,
		menuMode: String,
		colorScheme: String,
        topbarMenuActive: {
            type: Boolean,
            default: false
        },
        menuActive: {
            type: Boolean,
            default: false
        },
        activeInlineProfile: {
            type: Boolean,
            default: false
        }
	},
    setup(props, context) {
        return {
            identityPictureUrl: identity.pictureUrl(),
            onMenuClick(event) {
                context.emit('menu-click', event);
            },
            onMenuItemClick(event) {
                context.emit('menuitem-click', event);
            },
            onRootMenuItemClick(event) {
                context.emit('root-menuitem-click', event);
            },
            onMenuButtonClick(event) {
                context.emit('menubutton-click', event);
            },
            onTopbarItemClick(event, item) {
                context.emit('topbar-item-click', event, item);	
                event.preventDefault();
            },
            onSidebarMouseOver() {
                context.emit('sidebar-mouse-over');
            },
            onSidebarMouseLeave() {
                context.emit('sidebar-mouse-leave');
            },
            onToggleMenu(event) {
                context.emit('toggle-menu', event);
            },
            onChangeActiveInlineMenu() {
                context.emit('change-inlinemenu');
            },
            async logout() {
                await this.msal.logoutRedirect({
                    account: identity.user,
                    postLogoutRedirectUri: '/'
                });
            },
        }
	},
	components: {
		AppMenu
	}
}
</script>

<style lang="scss">
    .profile-item {
        img {
            border-radius: 50%;
        }
    }
</style>