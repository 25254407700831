import {createRouter, createWebHistory} from 'vue-router';
import { identity } from './service/Msal';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        exact: true,
        component: () => import('./pages/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/supervised',
        name: 'supervised',
        exact: true,
        component: () => import('./pages/Supervised.vue'),
        meta: {
            breadcrumb: [{ label: 'Supervised' }],
        },
    },
    {
        path: '/deadletters/:sbName/:queueName',
        name: 'deadletters',
        exact: true,
        component: () => import('./pages/Deadletters.vue'),
        meta: {
            breadcrumb: [{ label: 'Deadletter' }],
        },
    },
    {
        path: '/extrainfo',
        name: 'extrainfo',
        exact: true,
        component: () => import('./pages/ExtraInfo.vue'),
        meta: {
            breadcrumb: [{ label: 'Extra info' }],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
        meta: { 
            allowAnonymous: true,
            preventAuthenticated: true,
        }
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('./pages/Auth.vue'),
        meta: { allowAnonymous: true }
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.preventAuthenticated && identity.isAuthenticated) {
        next('/');

        return;
    }

    if (to.meta.allowAnonymous) {
        next();

        return;
    }

    if (!identity.isAuthenticated) {
        // On redirige sur le login
        next('/login');

        return;
    }

    next();
});

export default router;