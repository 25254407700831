<template>
	<div class="layout-breadcrumb-container">
		<div class="layout-breadcrumb-left-items">
			<a href="#" class="menu-button" @click="onMenuButtonClick($event)" v-if="isStatic()">
				<i class="pi pi-bars"></i>
			</a>

			<Breadcrumb :model="items" class="layout-breadcrumb"></Breadcrumb>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		menuMode: String,
	},
	emits: ['menubutton-click'],
	data() {
		return {
			items: [],
		}
	},
	watch: {
		$route() {
			this.watchRouter();
		}
	},
	created() {
		this.watchRouter();
	},
	methods: {
		watchRouter() {
			if(this.$router.currentRoute.value.meta.breadcrumb) {
				this.items = [];
				const bc = this.$router.currentRoute.value.meta.breadcrumb[0];
				for(let pro in bc) {
					this.items.push({label: bc[pro]});
				}
			}
		},
		onMenuButtonClick(event) {
			this.$emit('menubutton-click', event);
		},
		isStatic() {
			return this.menuMode === 'static';
		}
	}
}
</script>

<style scoped>
:deep(.p-breadcrumb > ul > li.p-breadcrumb-chevron:first-of-type) { 
	display: none;
}
</style>