<template>
    <div class="login-body">
        <div class="login-image">
            <img :src="'/layout/images/pages/login-'+ loginColor + '.png'" alt="atlantis">
        </div>
        <div class="login-panel p-fluid">
            <div class="p-d-flex p-flex-column">
                <div class="p-d-flex p-ai-center p-mb-6 logo-container">
                    <img :src="'/layout/images/logo-'+ color + '.png'" class="login-logo"/>
                    <img :src="'/layout/images/appname-'+ color + '-3.png'" class="login-appname"/>
                </div>
                <div class="button-container">
                    <router-link to="/">
                        <Button type="button" label="Login" @click.prevent="login()"></Button>
                    </router-link>
                </div>
            </div>

            <div class="login-footer p-d-flex p-ai-center">
                <div class="p-d-flex p-ai-center login-footer-logo-container">
                    <img src="/layout/images/logo-gray.png" class="login-footer-logo"/>
                    <img src="/layout/images/appname-gray.png" class="login-footer-appname"/>
                </div>
                <span>Copyright 2022</span>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, inject } from 'vue';
import { useRouter } from 'vue-router';
export default {
    setup() {
        const appState = inject('appState');
        const router = useRouter();

        return {
            loginColor: computed(() => appState.colorScheme === 'light' ? 'ondark' : 'onlight'),
            color: computed(() => appState.colorScheme === 'light' ? 'dark' : 'light'),
            login: () => router.replace({path: '/auth'}),
        };
    }
};
</script>