import * as msal from "@azure/msal-browser";
import { inject } from "vue";
import MD5 from 'crypto-js/md5';

const msalConfig = {
    auth: {
        clientId: "ddf77e7e-a424-4fa7-aad6-ed01b4712b0e",
        authority: "https://login.microsoftonline.com/sociabble.net",
        redirectUri: "/auth",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    // system: {	
    //     loggerOptions: {	
    //         loggerCallback: (level, message, containsPii) => {	
    //             if (containsPii) {		
    //                 return;		
    //             }		

    //             switch (level) {		
    //                 case msal.LogLevel.Error:		
    //                     console.error(message);		
    //                     return;		
    //                 case msal.LogLevel.Info:		
    //                     console.info(message);		
    //                     return;		
    //                 case msal.LogLevel.Verbose:		
    //                     console.debug(message);		
    //                     return;		
    //                 case msal.LogLevel.Warning:		
    //                     console.warn(message);		
    //                     return;		
    //             }	
    //         }	
    //     }	
    // }
};

if (process.env.NODE_ENV == 'development' && window.location.host == '192.168.250.63:8080') {
    console.warn('On dev, you should use the localhost domain and not the ip, else auth won\'t work');
}
export const msalInstance = new msal.PublicClientApplication(msalConfig);

export const identity = {
    isAuthenticated: false,
    user: null,
    pictureUrl() {
        if (null === this.user) {
            return '';
        }

        return `https://www.gravatar.com/avatar/${MD5(this.user.username.trim().toLowerCase())}`
    },
    setUser(user) {
        this.user = user;
        this.isAuthenticated = true;
    }
};


const msalPlugin = {
    install: (app) => {
        
        // On rend disponible l'instance msal partout dans Vue
        app.provide('msal', msalInstance);
        app.provide('identity', identity);

        // On cherche à ré-authentifier le user depuis le storage
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 1) {
            identity.setUser(accounts[0]);
        }
    }
};

export function useMsal() {
    return inject('msal');
}

export default msalPlugin;
  